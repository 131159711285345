<template>
    <div style="width: 100%">
        <div class="d-flex align-content-center position-relative">
            <div class="container-arrow-carousel arrows-carousel-left" v-if="minCarrusel">
                <a
                    href="#" 
                    @click.prevent="disminuirCarrusel"
                    class="arrow left"
                ></a>
            </div>
            <div class="flex-grow-1" style="height:100%">
                <CCollapse v-for="(item, index) in lineTimeArray" :key="index" :show="carruselTab === index+1"  class="p-arrows-carousel" >
                    <div class="card card_gris" style="width: 100%">
                        <div class="card-body">
                            <CRow style="width: 100%">
                                <CCol
                                    lg="4"
                                    v-for="(item2) in item"
                                    :key="item2.Id"
                                    style="width: 100%"
                                >
                                    <div
                                        class="card"
                                    >
                                        <div
                                            class="card-header text-center"
                                            :style="
                                                'background:' + item2.Color + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                                            "
                                        >
                                            <div class="d-flex">
                                                <div class="flex-grow-1"><img class="img-fluid rounded" height="30px" width="30px" src="/img/gruasOperativa.svg" alt="Card image cap"> {{ item2.CraneAlias }}</div>
                                            </div>
                                        </div>
                                        <div class="card-body" style="padding:0.25rem;">
                                            <div class="main-timeline-vertical-operation" v-if="item2.dataInformation.length !== 0">
                                                <section id="cd-timeline" class="cd-container">
                                                    <div class="cd-timeline-block" v-for="(item3, key) in item2.dataInformation" :key="key">
                                                        <div class="cd-timeline-img cd-movie">
                                                        </div>
                                                        <div class="cd-timeline-content">
                                                            <h6>{{item3.label}}</h6>
                                                            <p>{{item3.date}}</p>
                                                            <span class="cd-date">{{ item3.TotalTime }}</span>
                                                        </div> <!-- cd-timeline-content -->
                                                    </div>
                                                </section>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-center" v-else style="height:55vh">
                                                <h6 style="color:#F67272;text-align:center;" >{{$t('label.NoHasOperations')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </div>
                    </div>
                </CCollapse>
            </div>
            <div class="container-arrow-carousel arrows-carousel-right" v-if="maxCarrusel">
                <a
                    href="#" 
                    @click.prevent="aumentarCarrusel"
                    class="arrow right"
                ></a>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex';

    function data() {
        return {
            CranesProductivity: [],
        }
    }

    //methods
    function getTabProductivity() {
        this.$store.state.situacionOperativaActual.Loading = true;
        this.$http.ejecutar('GET', 'VisitOperationalSituationTabProductivity', {VisitId: this.Vessel.VisitId})
            .then(response => {
                let List = response.data.data;
                if (List&&List.length!=0) {
                    this.CranesProductivity = List[0]?.ProductivityJson[0]?.CranesProductivityJson ?
                        List[0]?.ProductivityJson[0]?.CranesProductivityJson : [];
                }
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            }).finally(()=>{
                this.$store.state.situacionOperativaActual.Loading = false;
            });
    }
    function disminuirCarrusel(){
        if(this.$store.state.situacionOperativaActual.carruselTab !== 1){
            this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab - 1;
        }
    }
    function aumentarCarrusel(){
        if(this.$store.state.situacionOperativaActual.carruselTab !== this.maxCarrusel){
            this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
        }
    }
    //computed
    function safeId () {
        // as long as this._uid() works there is no need to generate the key
        const key = () => Math.random().toString(36).replace('0.', '')
        return '__safe_id__lines_time_' + (this._uid || key())
    }
    function minCarrusel(){
        return this.$store.state.situacionOperativaActual.carruselTab > 1
    }
    function maxCarrusel(){
        return this.$store.state.situacionOperativaActual.carruselTab < this.lineTimeArray.length
    }
    function lineTimeArray() {
        let data = [], arregloDeArreglos = [];
        let DataLines = this.CranesProductivity;
        let labelsItem = '';
        let numberItem = '';
        DataLines.map(async(item) => {
            let labelsArray = this.$i18n.locale == 'es' ? item.CraneProductivityDetailJson[0].LabelEs : item.CraneProductivityDetailJson[0].LabelEn;
            let TotalTImeArray = item.CraneProductivityDetailJson[0].TotalTime;
            let dataInformation = [];
            for (let i = 1; i <= labelsArray.length;  i++) {
                if(i % 3 !== 0){
                    let index = i - 1;
                    let dataItem = item.CraneProductivityDetailJson[0].data[index] == null || item.CraneProductivityDetailJson[0].data[index] == undefined ? "" : 
                        moment(item.CraneProductivityDetailJson[0].data[index],'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm');
                    dataInformation.push({
                        label: labelsArray[index],
                        date: dataItem,
                        TotalTime: TotalTImeArray[index],
                    })
                }else{
                    if(item.CraneAlias !== labelsItem){
                        labelsItem = item.CraneAlias;
                        numberItem = 1;
                    }else{
                        numberItem++;
                    }
                    data.push({
                        Id: data.length,
                        IdX: item.IdX,
                        dataInformation: dataInformation,
                        Color: item.Color,
                        CraneAlias: labelsItem ,
                    });
                    dataInformation = [];
                    let index = i - 1;
                    let dataItem = item.CraneProductivityDetailJson[0].data[index] == null || item.CraneProductivityDetailJson[0].data[index] == undefined ? "" : 
                        moment(item.CraneProductivityDetailJson[0].data[index],'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm');
                    dataInformation.push({
                        label: labelsArray[index],
                        date: dataItem,
                        TotalTime: TotalTImeArray[index],
                    });
                }                
            }
            if(dataInformation.length !== 0){
                if(item.CraneAlias !== labelsItem){
                    labelsItem = item.CraneAlias;
                    numberItem = 1;
                }else{
                    numberItem++;
                }
                data.push({
                    Id: data.length,
                    IdX: item.IdX,
                    dataInformation: dataInformation,
                    Color: item.Color,
                    CraneAlias: labelsItem ,
                });
            }
        });
        let cantidades = 3;
        for (let i = 0; i < data.length; i += cantidades) {
            let pedazo = data.slice(i, i + cantidades);
            arregloDeArreglos.push(pedazo);
        }
        return arregloDeArreglos;
    }
    export default {
        name:'productivity',
        data,
        props: {
            Vessel: {
                type: Object,
                default: function () {
                    return {};
                },
            },
            ProductivityJson:{
                type: Object,
                default: function () {
                    return {};
                },
            }
        },
        methods:{
            getTabProductivity,
            disminuirCarrusel,
            safeId,
            aumentarCarrusel,
            _setInterval: function() {
                this.interval = setInterval(() => {
                    if(this.$store.state.situacionOperativaActual.carruselTab !== this.lineTimeArray.length){
                        this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
                    }else{
                        this.$store.state.situacionOperativaActual.carruselTab = 1;
                    }   
                }, 30000);
            }
        },
        beforeDestroy(){
            clearInterval(this.interval);
            this.interval = null;
        },
        computed:{
            lineTimeArray,
            minCarrusel,
            maxCarrusel,
            ...mapState({
                FgGeneralCargo: state => state.situacionOperativaActual.FgGeneralCargo,
                FgContainerShip: state => state.situacionOperativaActual.FgContainerShip,
                FgBulk: state => state.situacionOperativaActual.FgBulk,
                carrusel: state => state.situacionOperativaActual.carrusel,
                carruselTab : state=> state.situacionOperativaActual.carruselTab,
                tabsOperationes: state=> state.situacionOperativaActual.tabsOperationes,
            })
        },
        watch:{
            tabsOperationes(newValue,OldValue){
                let tabsOperation = this.FgContainerShip ? 4 : this.FgGeneralCargo ? 5 : 6;
                if(OldValue === tabsOperation){
                    this.CranesProductivity = [];
                    this.$store.state.situacionOperativaActual.carruselTab = 1;
                    clearInterval(this.interval);
                    this.interval = null;
                }else if(newValue === tabsOperation){
                    if (this.FgBulk) {
                        this.getTabProductivity();
                    } else {
                        this.CranesProductivity = this.ProductivityJson?.CranesProductivityJson ? 
                            this.ProductivityJson?.CranesProductivityJson : [];
                    }
                    this.$nextTick(async () => {
                        if(this.carrusel){
                            this._setInterval();
                        }
                    });
                }
            },
            carrusel(newValue){
                let tabsOperation = this.FgContainerShip ? 4 : this.FgGeneralCargo ? 5 : 6;
                if(this.tabsOperationes === tabsOperation){
                    if(newValue){
                        this.$nextTick(async () => {
                            this._setInterval();
                        });
                    }else{
                        clearInterval(this.interval);
                        this.interval = null;
                    }
                }
            }
        }
    }
</script>

<style lang="scss" >
    @import 'css/line_time.scss';
</style>